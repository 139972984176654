@use 'styles/includes' as *;

.Accordion {
    $root: &;

    border-bottom: 1px solid $grey-40;

    &__Button {
        padding: 3rem 5rem 3rem 0;
        width: 100%;
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        font-size: 2.4rem;
        line-height: 100%;
        text-align: left;
        color: $black;
        background-color: transparent;
        border: 0;
        position: relative;

        &:hover {
            cursor: pointer;
        }

        @include media(s) {
            padding: 3rem 9rem 3rem 0;
            font-size: 3rem;
        }
    }

    &__Content {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 150ms ease-out 0s;

        #{$root}--Expanded & {
            grid-template-rows: 1fr;
        }
    }

    &__Inner {
        overflow: hidden;
    }

    &__RichText {
        padding: 0 0 3rem;
        visibility: hidden;
        transition: visibility 150ms ease-out;

        * {
            &:not(:last-child) {
                margin: 0 0 2.4rem;

                @include media(m) {
                    margin: 0 0 3rem;
                }
            }
        }

        a {
            @extend %link;
        }

        p {
            font-family: $font-family-regular;
            font-size: 1.6rem;
            font-weight: $font-weight-regular;
            line-height: 130%;
            color: $grey-60;

            @include media(m) {
                font-size: 2rem;
            }
        }

        ol {
            @extend %ol;
            color: $grey-60;
        }

        ul {
            @extend %ul;
            color: $grey-60;
        }

        #{$root}--Expanded & {
            visibility: visible;
        }
    }
}
